import { useEffect, useRef, useState } from "react"
import { DatePicker, DatepickerProps } from "baseui/datepicker"

type DateRangePickerProps = Omit<
  DatepickerProps,
  "range" | "onRangeChange" | "onChange"
> & {
  onChange?: (range: [Date, Date]) => void
  initialRange?: [Date, Date]
}

// 기본 Datepicker를 range 옵션으로 사용하면 두 날짜 구간 중 한 곳만 체크하면 반대쪽이 undefined가 된다.
// 하지만 해당 range 양쪽 경계가 모두 설정되었을 때 API 콜을 한다든가, 하는 경우에는 원치 않은 동작이 일어날 수 있다.
// 두 날짜를 모두 입력했을 때만 onChange가 불리는 컴포넌트이다.
export function DateRangePicker(props: DateRangePickerProps) {
  const { onChange, ...datePickerProps } = props
  const onChangeRef = useRef(onChange)
  useEffect(() => {
    onChangeRef.current = onChange
  }, [onChange])
  const now = new Date()
  const [startDate, setStartDate] = useState<Date | undefined>(
    props.initialRange ? props.initialRange[0] : now
  )
  const [endDate, setEndDate] = useState<Date | undefined>(
    props.initialRange ? props.initialRange[1] : now
  )
  useEffect(() => {
    if (!startDate || !endDate || !onChangeRef.current) return
    onChangeRef.current([startDate, endDate])
  }, [startDate, endDate])
  return (
    <DatePicker
      {...datePickerProps}
      autoFocusCalendar={false}
      range
      value={[startDate, endDate]}
      onRangeChange={({ date }) => {
        const [d1, d2] = (Array.isArray(date) ? date : [date]) as (
          | Date
          | undefined
        )[]
        setStartDate(d1)
        setEndDate(d2)
      }}
    />
  )
}
