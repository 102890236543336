import React, { PropsWithChildren, useCallback, useMemo, useState } from "react"
import {
  Crosshair,
  DiscreteColorLegend,
  Hint,
  HorizontalGridLines,
  makeWidthFlexible,
  VerticalBarSeries,
  VerticalGridLines,
  XAxis,
  XYPlot,
  YAxis,
} from "react-vis"
import { EventWindow } from "./models"
import dayjs, { Dayjs } from "dayjs"

const FlexibleXYPlot = makeWidthFlexible(XYPlot)

type EventGraphProps = {
  eventWindows: EventWindow[]
}

export function EventCountsGraph({
  eventWindows,
  children,
}: PropsWithChildren<EventGraphProps>) {
  const { slaLimit, dataSet, tickFormat, tickValues, maxY, windowSize } =
    useMemo(() => {
      const windowSize = dayjs(eventWindows[0].windowEndTime).diff(
        eventWindows[0].windowStartTime,
        "seconds"
      )
      const dateRange = eventWindows.length * windowSize

      const dates = eventWindows
        .map((w) => w.windowStartTime)
        .map((t) => dayjs(t))
      const slaLimit =
        dateRange <= 24 * 60 * 60
          ? dates[0].hour() < 9
            ? dates[0].startOf("day")
            : dates[0].startOf("day").add(1, "day")
          : null

      const dataSet: {
        [k in "delivered" | "hold" | "quit" | "lost"]: {
          x: number
          y: number
        }[]
      } = {
        delivered: [],
        hold: [],
        quit: [],
        lost: [],
      }
      eventWindows.forEach((w) => {
        const x = dayjs(w.windowStartTime)
          .add(windowSize / 2, "seconds")
          .unix()
        dataSet.delivered.push({ x: x, y: w.deliveredCount })
        dataSet.hold.push({ x: x, y: w.holdDeliveryCount })
        dataSet.quit.push({ x: x, y: w.quitCount })
        dataSet.lost.push({ x: x, y: w.lostCount })
      })
      const { numWindows: numWindowsPerTick, format: tickFormat } =
        getTickFormat(windowSize, dateRange)
      const tickValues = dataSet.delivered
        .map(({ x }) => x - windowSize / 2)
        .filter((x, i) => i % numWindowsPerTick === 0)
      const maxY = Math.max(...dataSet.delivered.map(({ y }) => y))
      return { slaLimit, dataSet, tickFormat, tickValues, maxY, windowSize }
    }, [eventWindows])

  const [tooltip, setTooltip] = useState<{ [k in string]: number }>()
  const onHover = useCallback(
    (v: number) => {
      setTooltip({
        x: v,
        delivered: dataSet.delivered.find(({ x }) => x === v)?.y ?? 0,
        hold: dataSet.hold.find(({ x }) => x === v)?.y ?? 0,
        quit: dataSet.quit.find(({ x }) => x === v)?.y ?? 0,
        lost: dataSet.lost.find(({ x }) => x === v)?.y ?? 0,
      })
    },
    [dataSet]
  )
  const onHoverFinished = useCallback(
    (x: number) => {
      if (tooltip?.x !== x) return
      setTooltip(undefined)
    },
    [tooltip]
  )
  return (
    <>
      <FlexibleXYPlot
        height={340}
        stackBy="y"
        margin={{ left: maxY < 100 ? 50 : maxY < 1000 ? 60 : 75 }}
      >
        <VerticalGridLines tickValues={tickValues} />
        <HorizontalGridLines />
        <XAxis
          tickValues={tickValues}
          tickFormat={(v) => tickFormat(dayjs(v * 1000))}
        />
        <YAxis
          left={0}
          tickFormat={(v) => (Math.round(v) === v ? `${v}건` : "")}
        />
        <VerticalBarSeries
          barWidth={0.8}
          data={dataSet.delivered}
          color={"#12939a"}
          onValueMouseOver={({ x }) => onHover(x as number)}
          onValueMouseOut={({ x }) => onHoverFinished(x as number)}
        />
        <VerticalBarSeries
          barWidth={0.8}
          data={dataSet.hold}
          color={"#ffc456"}
          onValueMouseOver={({ x }) => onHover(x as number)}
          onValueMouseOut={({ x }) => onHoverFinished(x as number)}
        />
        <VerticalBarSeries
          barWidth={0.8}
          data={dataSet.quit}
          color={"#9a1212"}
          onValueMouseOver={({ x }) => onHover(x as number)}
          onValueMouseOut={({ x }) => onHoverFinished(x as number)}
        />
        <VerticalBarSeries
          barWidth={0.8}
          data={dataSet.lost}
          color={"#ff6900"}
          onValueMouseOver={({ x }) => onHover(x as number)}
          onValueMouseOut={({ x }) => onHoverFinished(x as number)}
        />
        <Crosshair
          values={[{ x: dayjs().unix() }]}
          style={{ line: { backgroundColor: "#ffc456" } }}
        >
          <h3 className="text-[#ffc456]">Now</h3>
        </Crosshair>
        {slaLimit && (
          <Crosshair
            values={[{ x: slaLimit.unix() }]}
            style={{ line: { backgroundColor: "red" } }}
          >
            <h3 className="text-red-500">SLI Limit</h3>
          </Crosshair>
        )}
        {children}
        {tooltip && (
          <Hint
            value={{
              x: tooltip.x,
              y: tooltip.delivered + tooltip.hold + tooltip.quit + tooltip.lost,
            }}
            align={{ horizontal: "right" }}
          >
            <div className="relative mb-3 ml-[-50%] w-52">
              <div className="rounded-sm border border-gray-500 bg-white py-3 text-center text-sm text-black">
                <p className="font-bold">
                  {dayjs((tooltip.x - windowSize / 2) * 1000).format(
                    "M/D HH:mm"
                  )}{" "}
                  ~{" "}
                  {dayjs((tooltip.x + windowSize / 2) * 1000).format(
                    "M/D HH:mm"
                  )}
                </p>
                <div className="mx-auto mt-2 grid w-40 grid-cols-2 gap-2 text-left text-xs">
                  <p>
                    <span className="mr-1 inline-block h-2 w-2 rounded-full bg-[#12939A]" />
                    {tooltip.delivered.toLocaleString()}건
                  </p>
                  <p>
                    <span className="mr-1 inline-block h-2 w-2 rounded-full bg-[#ffc456]" />
                    {tooltip.hold.toLocaleString()}건
                  </p>
                  <p>
                    <span className="mr-1 inline-block h-2 w-2 rounded-full bg-[#9a1212]" />
                    {tooltip.quit.toLocaleString()}건
                  </p>
                  <p>
                    <span className="mr-1 inline-block h-2 w-2 rounded-full bg-[#ff6900]" />
                    {tooltip.lost.toLocaleString()}건
                  </p>
                </div>
              </div>
              <div className="absolute bottom-[-3] left-[50%] h-3 border border-gray-800" />
            </div>
          </Hint>
        )}
      </FlexibleXYPlot>
      <DiscreteColorLegend
        className="mt-[-9px] overflow-y-visible"
        orientation="horizontal"
        items={[
          {
            title: "배송완료",
            color: "#12939A",
          },
          {
            title: "배송보류",
            color: "#ffc456",
          },
          {
            title: "배송중단",
            color: "#9a1212",
          },
          {
            title: "분실",
            color: "#ff6900",
          },
        ]}
      />
    </>
  )
}

function getTickFormat(windowSize: number, dateRange: number) {
  const hour = 60 * 60
  const day = 24 * hour
  if (dateRange <= day) {
    return {
      numWindows: Math.max(1, Math.floor(hour / windowSize)),
      format: (d: Dayjs) => d.format("HH:mm"),
    }
  }
  if (dateRange <= 4 * day) {
    return {
      numWindows: Math.max(1, Math.floor((3 * hour) / windowSize)),
      format: (d: Dayjs) => d.format("M/D H시"),
    }
  }
  if (dateRange <= 7 * day) {
    return {
      numWindows: Math.max(1, Math.floor((12 * hour) / windowSize)),
      format: (d: Dayjs) => d.format("M/D H시"),
    }
  }
  if (dateRange <= 31 * day) {
    return {
      numWindows: Math.max(1, Math.floor(day / windowSize)),
      format: (d: Dayjs) => d.format("M/D"),
    }
  }
  if (dateRange <= 3 * 31 * day) {
    return {
      numWindows: Math.max(1, Math.floor((2 * day) / windowSize)),
      format: (d: Dayjs) => d.format("M/D"),
    }
  }
  return {
    numWindows: Math.max(1, Math.floor((7 * day) / windowSize)),
    format: (d: Dayjs) => d.format("M/D"),
  }
}
