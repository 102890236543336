import { EventWindow } from "./models"
import dayjs from "dayjs"

export function mergeWindows(windows: EventWindow[], windowSecs: number) {
  if (windows.length === 0) return []
  const initialWindow = EventWindow.empty(windows[0])
  const firstTime = dayjs(initialWindow.windowStartTime)
  const startTime =
    firstTime.hour() < 9
      ? firstTime.startOf("day").subtract(1, "day").add(9, "hours")
      : firstTime.startOf("day").add(9, "hours")
  const endTime = startTime.add(windowSecs, "seconds")
  initialWindow.windowStartTime = startTime.toISOString()
  initialWindow.windowEndTime = endTime.toISOString()

  return windows
    .sort((a, b) => {
      return dayjs(a.windowStartTime).isAfter(b.windowStartTime) ? 1 : -1
    })
    .reduce<EventWindow[]>(
      (acc, eventWindow) => {
        const last = acc[acc.length - 1]
        if (dayjs(eventWindow.windowStartTime).isBefore(last.windowEndTime)) {
          last.add(eventWindow)
          return acc
        } else {
          const newWindow = EventWindow.empty(eventWindow)
          newWindow.add(eventWindow)
          newWindow.windowStartTime = eventWindow.windowStartTime
          newWindow.windowEndTime = dayjs(eventWindow.windowStartTime)
            .add(windowSecs, "seconds")
            .toISOString()
          return [...acc, newWindow]
        }
      },
      [initialWindow]
    )
}

export class Window {
  eventTimeWindow: EventWindow
  targetDeliverTimeWindow: EventWindow
  mergedTargetDeliverTimeWindow: EventWindow
  dailyWindow: EventWindow
  totalLoadsCountOfToday?: number
  useDaily: boolean

  constructor(
    eventTimeWindow: EventWindow,
    targetDeliverTimeWindow: EventWindow,
    mergedTargetDeliverTimeWindow: EventWindow,
    dailyWindow: EventWindow,
    totalLoadsCountOfToday?: number,
    useDaily: boolean = false
  ) {
    this.eventTimeWindow = eventTimeWindow
    this.targetDeliverTimeWindow = targetDeliverTimeWindow
    this.mergedTargetDeliverTimeWindow = mergedTargetDeliverTimeWindow
    this.dailyWindow = dailyWindow
    this.totalLoadsCountOfToday = totalLoadsCountOfToday
    this.useDaily = useDaily
  }

  get windowStartTime() {
    return dayjs(this.eventTimeWindow.windowStartTime)
  }

  get windowEndTime() {
    return dayjs(this.eventTimeWindow.windowEndTime)
  }

  get pickUpCount() {
    return this.eventTimeWindow.pickUpCount
  }

  get totalDeliverTargetCount() {
    const now = dayjs()
    if (
      this.totalLoadsCountOfToday &&
      this.windowStartTime.isBefore(now) &&
      now.isBefore(this.windowEndTime)
    ) {
      return this.totalLoadsCountOfToday
    }
    return this.dailyWindow.totalDeliverTargetCount
  }

  get _deliveryWindow() {
    return this.useDaily ? this.dailyWindow : this.targetDeliverTimeWindow
  }

  get _mergedDeliveryWindow() {
    return this.useDaily ? this.dailyWindow : this.mergedTargetDeliverTimeWindow
  }

  get deliveredCount() {
    return this._deliveryWindow.deliveredCount
  }

  get deliveredRatio() {
    return (
      this._mergedDeliveryWindow.deliveredCount /
      Math.max(1, this.dailyWindow.totalDeliverTargetCount)
    )
  }

  get deliveredInTimeCount() {
    return this._deliveryWindow.deliveredInTimeCount
  }

  get deliveredInTimeRatio() {
    return (
      this._mergedDeliveryWindow.deliveredInTimeCount /
      Math.max(1, this.dailyWindow.totalDeliverTargetCount)
    )
  }

  get holdDeliveryCount() {
    return this._deliveryWindow.holdDeliveryCount
  }

  get holdDeliveryRatio() {
    return (
      this._mergedDeliveryWindow.holdDeliveryCount /
      Math.max(1, this.dailyWindow.totalDeliverTargetCount)
    )
  }

  get quitCount() {
    return this._deliveryWindow.quitCount
  }

  get quitRatio() {
    return (
      this._mergedDeliveryWindow.quitCount /
      Math.max(1, this.dailyWindow.totalDeliverTargetCount)
    )
  }

  get lostCount() {
    return this._deliveryWindow.lostCount
  }

  get lostRatio() {
    return (
      this._mergedDeliveryWindow.lostCount /
      Math.max(1, this.dailyWindow.totalDeliverTargetCount)
    )
  }
}
