import dayjs from "dayjs"

export const dateOptions = (function () {
  const now = dayjs()
  const today = now.startOf("day").add(now.hour() < 9 ? -1 : 0, "day")
  return [
    {
      id: "오늘",
      beginDate: today.toDate(),
      endDate: today.toDate(),
    },
    {
      id: "어제",
      beginDate: today.subtract(1, "day").toDate(),
      endDate: today.subtract(1, "day").toDate(),
    },
    {
      id: "이번 주",
      beginDate: today.startOf("isoWeek").toDate(),
      endDate: today.endOf("isoWeek").startOf("day").toDate(),
    },
    {
      id: "지난 주",
      beginDate: today.subtract(1, "week").startOf("isoWeek").toDate(),
      endDate: today
        .subtract(1, "week")
        .endOf("isoWeek")
        .startOf("day")
        .toDate(),
    },
    {
      id: "이번 달",
      beginDate: today.startOf("month").toDate(),
      endDate: today.endOf("month").startOf("day").toDate(),
    },
    {
      id: "지난 달",
      beginDate: today.subtract(1, "month").startOf("month").toDate(),
      endDate: today
        .subtract(1, "month")
        .endOf("month")
        .startOf("day")
        .toDate(),
    },
    {
      id: "올해",
      beginDate: today.startOf("year").toDate(),
      endDate: today.endOf("month").startOf("day").toDate(),
    },
  ]
})()
