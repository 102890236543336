import { EventWindow } from "./models"
import { TableBuilder, TableBuilderColumn } from "baseui/table-semantic"
import { useMemo, useState } from "react"
import dayjs, { Dayjs } from "dayjs"
import { toPercent } from "../../utils"
import { Window, mergeWindows } from "./Window"

type EventCountTableProps = {
  eventTimeWindows: EventWindow[]
  targetDeliverTimeWindows: EventWindow[]
  dailyWindows: EventWindow[]
  isLoading?: boolean
  emptyMessage?: string
  hideFuture?: boolean

  // 오늘자 데이터에 대해 usePickUpCount가 false인 경우 본 값을 사용한다.
  totalLoadsCountOfToday?: number
}

const SORT_KEYS = {
  windowStartTime: "WINDOW_START_TIME",
  pickUpCount: "PICK_UP_COUNT",
  deliveredCount: "DELIVERED_COUNT",
  deliveredRatio: "DELIVERED_RATIO",
  deliveredInTimeCount: "DELIVERED_IN_TIME_COUNT",
  deliveredInTimeRatio: "DELIVERED_IN_TIME_RATIO",
  holdDeliveryCount: "HOLD_DELIVERY_COUNT",
  holdDeliveryRatio: "HOLD_DELIVERY_RATIO",
  quitCount: "QUIT_COUNT",
  quitRatio: "QUIT_RATIO",
  lostCount: "LOST_COUNT",
  lostRatio: "LOST_RATIO",
}

export function EventCountTable({
  eventTimeWindows,
  targetDeliverTimeWindows,
  dailyWindows,
  isLoading,
  emptyMessage,
  totalLoadsCountOfToday = 0,
  hideFuture = false,
}: EventCountTableProps) {
  const [sortColumn, setSortColumn] = useState<string>(
    SORT_KEYS.windowStartTime
  )
  const [sortAsc, setSortAsc] = useState(true)

  // 윈도우 병합
  const [windowSecs, totalSecs, isMultipleDays] = useMemo(() => {
    let windowSecs = 3600 * 24
    let totalSecs = 0
    if (eventTimeWindows.length > 0) {
      const secs =
        dayjs(eventTimeWindows[0].windowEndTime).diff(
          eventTimeWindows[0].windowStartTime
        ) / 1000
      if (secs > windowSecs) {
        windowSecs = secs
      }
      totalSecs =
        dayjs(eventTimeWindows[eventTimeWindows.length - 1].windowEndTime).diff(
          eventTimeWindows[0].windowStartTime
        ) / 1000
    }
    return [windowSecs, totalSecs, totalSecs > 3600 * 24]
  }, [eventTimeWindows])
  const mergedDailyWindows = useMemo(
    () => mergeWindows(dailyWindows, windowSecs),
    [dailyWindows, windowSecs]
  )
  const mergedEventTimeWindows = useMemo(() => {
    if (totalSecs <= 3600 * 24) return eventTimeWindows
    return mergeWindows(eventTimeWindows, windowSecs)
  }, [eventTimeWindows, windowSecs, totalSecs])
  const mergedTargetDeliverTimeWindows = useMemo(() => {
    if (totalSecs <= 3600 * 24) return targetDeliverTimeWindows
    return mergeWindows(targetDeliverTimeWindows, windowSecs)
  }, [targetDeliverTimeWindows, windowSecs, totalSecs])

  const rows = useMemo(() => {
    if (
      mergedEventTimeWindows.length === 0 ||
      mergedTargetDeliverTimeWindows.length === 0
    )
      return []
    return mergedEventTimeWindows.map((eventWindow, i) => {
      const startTime = dayjs(eventWindow.windowStartTime)
      const endTime = dayjs(eventWindow.windowEndTime)
      const targetDeliverTimeWindow = mergedTargetDeliverTimeWindows
        .filter((w) => {
          const st = dayjs(w.windowStartTime)
          return !st.isBefore(startTime) && st.isBefore(endTime)
        })
        .reduce((acc, w) => {
          acc.add(w)
          return acc
        }, EventWindow.empty(mergedTargetDeliverTimeWindows[0]))
      const mergedTargetDeliverTimeWindow = mergedTargetDeliverTimeWindows
        .filter((w) => {
          const st = dayjs(w.windowStartTime)
          return st.isBefore(endTime)
        })
        .reduce((acc, w) => {
          acc.add(w)
          return acc
        }, EventWindow.empty(mergedTargetDeliverTimeWindows[0]))
      const dailyWindow =
        mergedDailyWindows.find((w) => {
          const st = dayjs(w.windowStartTime)
          const et = dayjs(w.windowEndTime)
          return !startTime.isBefore(st) && startTime.isBefore(et)
        }) ?? EventWindow.empty(mergedTargetDeliverTimeWindows[0])
      return new Window(
        eventWindow,
        targetDeliverTimeWindow,
        mergedTargetDeliverTimeWindow,
        dailyWindow,
        totalLoadsCountOfToday,
        isMultipleDays
      )
    })
  }, [
    mergedDailyWindows,
    mergedEventTimeWindows,
    mergedTargetDeliverTimeWindows,
    totalLoadsCountOfToday,
    isMultipleDays,
  ])

  const sortedRows = useMemo(() => {
    if (!sortColumn) return rows
    return [...rows].sort((a: Window, b: Window) => {
      const left = sortAsc ? a : b
      const right = sortAsc ? b : a
      const leftValue = getSortKey(left, sortColumn)
      const rightValue = getSortKey(right, sortColumn)
      if (typeof leftValue === "string" && typeof rightValue === "string") {
        return leftValue.localeCompare(rightValue, "en", {
          numeric: true,
          sensitivity: "base",
        })
      }
      if (typeof leftValue === "number" && typeof rightValue === "number") {
        return leftValue - rightValue
      }
      // unreachable
      return 0
    })
  }, [sortColumn, sortAsc, rows])
  const handleSort = (id: string) => {
    if (id === sortColumn) {
      setSortAsc((asc) => !asc)
    } else {
      setSortColumn(id)
      setSortAsc(true)
    }
  }

  return (
    <TableBuilder
      data={sortedRows}
      sortColumn={sortColumn}
      sortOrder={sortAsc ? "ASC" : "DESC"}
      onSort={handleSort}
      isLoading={isLoading}
      emptyMessage={emptyMessage ?? "운송 통계 데이터가 존재하지 않습니다."}
      overrides={{
        Root: {
          style: {
            maxHeight: "100%",
          },
        },
        TableBodyCell: {
          style: (v) => {
            const row = v["$row"] as Window
            if (
              row.windowEndTime.diff(row.windowStartTime, "hours") >= 24 &&
              row.pickUpCount === 0
            ) {
              return {
                backgroundColor: "#eaeaea",
                color: "#a8a8a8",
              }
            }
            if (row.windowStartTime.isAfter(dayjs())) {
              return {
                backgroundColor: "#eaeaea",
                color: "#a8a8a8",
              }
            }
          },
        },
      }}
    >
      <TableBuilderColumn header="일시" id={SORT_KEYS.windowStartTime} sortable>
        {(window: Window) => (
          <TimeInterval
            startTime={window.windowStartTime}
            endTime={window.windowEndTime}
          />
        )}
      </TableBuilderColumn>
      <TableBuilderColumn
        header="집화 물품수"
        id={SORT_KEYS.pickUpCount}
        sortable
        numeric
      >
        {(window: Window) => window.pickUpCount.toLocaleString()}
      </TableBuilderColumn>
      {isMultipleDays && (
        <TableBuilderColumn
          header="배송 대상 물품수"
          id={SORT_KEYS.pickUpCount}
          sortable
          numeric
        >
          {(window: Window) => window.totalDeliverTargetCount.toLocaleString()}
        </TableBuilderColumn>
      )}
      <TableBuilderColumn
        header="배송 완료수"
        id={SORT_KEYS.deliveredCount}
        sortable
        numeric
      >
        {(window: Window) => window.deliveredCount.toLocaleString()}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={isMultipleDays ? "배송 완료율" : "누적 배송 완료율"}
        id={SORT_KEYS.deliveredRatio}
        sortable={isMultipleDays}
        numeric
      >
        {(window: Window) => toPercent(Math.min(1, window.deliveredRatio), 2)}
      </TableBuilderColumn>
      {isMultipleDays && (
        <TableBuilderColumn
          header="시간 내 배송 완료수"
          id={SORT_KEYS.deliveredInTimeCount}
          sortable
          numeric
        >
          {(window: Window) => window.deliveredInTimeCount.toLocaleString()}
        </TableBuilderColumn>
      )}
      {isMultipleDays && (
        <TableBuilderColumn
          header="시간 내 배송 완료율"
          id={SORT_KEYS.deliveredInTimeRatio}
          sortable
          numeric
        >
          {(window: Window) =>
            toPercent(Math.min(1, window.deliveredInTimeRatio), 2)
          }
        </TableBuilderColumn>
      )}
      <TableBuilderColumn
        header="배송 보류수"
        id={SORT_KEYS.holdDeliveryCount}
        sortable
        numeric
      >
        {(window: Window) => window.holdDeliveryCount.toLocaleString()}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={isMultipleDays ? "배송 보류율" : "누적 배송 보류율"}
        id={SORT_KEYS.holdDeliveryRatio}
        sortable={isMultipleDays}
        numeric
      >
        {(window: Window) => toPercent(window.holdDeliveryRatio, 2)}
      </TableBuilderColumn>
      <TableBuilderColumn
        header="배송 중단수"
        id={SORT_KEYS.quitCount}
        sortable
        numeric
      >
        {(window: Window) => window.quitCount.toLocaleString()}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={isMultipleDays ? "배송 중단율" : "누적 배송 중단율"}
        id={SORT_KEYS.quitRatio}
        sortable={isMultipleDays}
        numeric
      >
        {(window: Window) => toPercent(window.quitRatio, 3)}
      </TableBuilderColumn>
      <TableBuilderColumn
        header="물품 분실수"
        id={SORT_KEYS.lostCount}
        sortable
        numeric
      >
        {(window: Window) => window.quitCount.toLocaleString()}
      </TableBuilderColumn>
      <TableBuilderColumn
        header={isMultipleDays ? "물품 분실율" : "누적 물품 분실율"}
        id={SORT_KEYS.lostRatio}
        sortable={isMultipleDays}
        numeric
      >
        {(window: Window) => toPercent(window.lostRatio, 3)}
      </TableBuilderColumn>
    </TableBuilder>
  )
}

function TimeInterval({
  startTime,
  endTime,
}: {
  startTime: Dayjs
  endTime: Dayjs
}) {
  const diffMins = endTime.diff(startTime, "minutes")
  if (startTime.hour() === 9 && startTime.add(1, "day").isSame(endTime)) {
    if (diffMins === 60 * 24) {
      return <>{startTime.format("YYYY.MM.DD")}</>
    }
    return (
      <>{`${startTime.format("YYYY.MM.DD")} ~ ${endTime.format("MM.DD")}`}</>
    )
  }
  return (
    <>{`${startTime.format("YYYY.MM.DD HH:mm")} ~ ${endTime.format(
      "YYYY.MM.DD HH:mm"
    )}`}</>
  )
}

function getSortKey(window: Window, columnId: string): string | number {
  switch (columnId) {
    case SORT_KEYS.windowStartTime:
      return window.windowStartTime.toISOString()
    case SORT_KEYS.pickUpCount:
      return window.pickUpCount
    case SORT_KEYS.deliveredCount:
      return window.deliveredCount
    case SORT_KEYS.deliveredRatio:
      return window.deliveredRatio
    case SORT_KEYS.holdDeliveryCount:
      return window.holdDeliveryCount
    case SORT_KEYS.holdDeliveryRatio:
      return window.holdDeliveryRatio
    case SORT_KEYS.quitCount:
      return window.quitCount
    case SORT_KEYS.quitRatio:
      return window.quitRatio
    case SORT_KEYS.lostCount:
      return window.lostCount
    case SORT_KEYS.lostRatio:
      return window.lostRatio
  }
  return ""
}
