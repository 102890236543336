import { Button } from "baseui/button"
import { useEffect, useMemo, useRef, useState } from "react"
import { ButtonGroup, MODE, SIZE as BG_SIZE } from "baseui/button-group"
import dayjs, { Dayjs } from "dayjs"

type EventCountsWindowSizeSettingProps = {
  startTime: Dayjs
  endTime: Dayjs
  onChangeWindowSize(windowSizeSecs: number): void
}

const windowSizeOptions = [
  {
    minDays: 0,
    maxDays: 1,
    seconds: 10 * 60,
    displayName: "10분",
  },
  {
    minDays: 0,
    maxDays: 7,
    seconds: 30 * 60,
    displayName: "30분",
  },
  {
    minDays: 0,
    maxDays: 7,
    seconds: 60 * 60,
    displayName: "1시간",
  },
  {
    minDays: 0,
    maxDays: 31,
    seconds: 3 * 60 * 60,
    displayName: "3시간",
  },
  {
    minDays: 7,
    maxDays: 2 * 31,
    seconds: 6 * 60 * 60,
    displayName: "6시간",
  },
  {
    minDays: 14,
    maxDays: 3 * 31,
    seconds: 12 * 60 * 60,
    displayName: "12시간",
  },
  {
    minDays: 2,
    maxDays: 366,
    seconds: 24 * 60 * 60,
    displayName: "1일",
  },
  {
    minDays: 28,
    maxDays: Number.MAX_VALUE,
    seconds: 7 * 24 * 60 * 60,
    displayName: "1주",
  },
]

function initialSize(options: typeof windowSizeOptions) {
  return options.find((o) => o.seconds === 3600)
    ? 3600
    : options.find((o) => o.seconds === 24 * 3600)
    ? 24 * 3600
    : options.length > 1
    ? options[1].seconds
    : options[0].seconds
}

export function EventCountsWindowSizeSetting({
  startTime,
  endTime,
  onChangeWindowSize,
}: EventCountsWindowSizeSettingProps) {
  const onChangeWindowSizeRef = useRef(onChangeWindowSize)
  useEffect(() => {
    onChangeWindowSizeRef.current = onChangeWindowSize
  }, [onChangeWindowSize])

  const diff = useMemo(
    () => endTime.diff(startTime, "days"),
    [startTime, endTime]
  )
  const options = useMemo(
    () =>
      windowSizeOptions.filter((o) => o.minDays <= diff && diff <= o.maxDays),
    [diff]
  )
  const [windowSizeSecs, setWindowSizeSecs] = useState(initialSize(options))
  const windowSizeSecsRef = useRef(windowSizeSecs)

  useEffect(() => {
    if (options.findIndex((e) => windowSizeSecsRef.current === e.seconds) < 0) {
      const size = initialSize(options)
      windowSizeSecsRef.current = size
      setWindowSizeSecs(size)
    }
    onChangeWindowSizeRef.current(windowSizeSecsRef.current)
  }, [startTime, endTime, diff, options])

  return (
    <div className="flex items-center gap-x-3">
      <p>그래프 간격</p>
      <ButtonGroup
        mode={MODE.radio}
        size={BG_SIZE.mini}
        selected={options.findIndex((e) => windowSizeSecs === e.seconds)}
        onClick={(_event, index) => {
          const s = options[index].seconds
          windowSizeSecsRef.current = s
          setWindowSizeSecs(s)
          onChangeWindowSize(s)
        }}
      >
        {options.map(({ displayName }) => (
          <Button key={displayName}>{displayName}</Button>
        ))}
      </ButtonGroup>
    </div>
  )
}

export function useWindowSizeSecs(
  initialStartTime?: string,
  initialEndTime?: string
): [number, (s: number) => void] {
  const initialWindowSizeSecs = useMemo(() => {
    if (!initialStartTime || !initialEndTime)
      // 시간이 주어지지 않은 경우 당일 데이터로 간주하여 1시간 단위로 출력
      return 3600
    const diff = dayjs(initialEndTime).diff(initialStartTime, "days")
    const options = windowSizeOptions.filter(
      (o) => o.minDays <= diff && diff <= o.maxDays
    )
    return initialSize(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const [windowSizeSecs, setWindowSizeSecs] = useState(initialWindowSizeSecs)
  return [
    windowSizeSecs,
    (s: number) => {
      setWindowSizeSecs(s)
    },
  ]
}
