import {
  IResourceStats,
  IStats,
  TargetTimeType,
  IEventWindow,
  TimeInterval,
  ClientsSummary,
} from "@today/api/tracker"
import {
  EventWindow,
  ResourceStats,
  Stats,
  DriverStats,
} from "./components/dashboard/models"
import { DeliveryClass } from "@today/api/common"
import useSWR from "swr"
import dayjs, { Dayjs } from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"

type StatsParams = {
  startTime?: string
  endTime?: string
  timeIntervalTarget?: TargetTimeType
  deliveryClasses?: DeliveryClass[]
  senderSiDos?: string[]
  receiverSiDos?: string[]
  clientId?: string
}

function useStatsParams({
  startTime,
  endTime,
  timeIntervalTarget,
  deliveryClasses,
  senderSiDos,
  receiverSiDos,
  clientId,
}: StatsParams): StatsParams {
  return useMemo(
    () => ({
      startTime,
      endTime,
      timeIntervalTarget,
      deliveryClasses,
      senderSiDos,
      receiverSiDos,
      clientId,
    }),
    [
      startTime,
      endTime,
      timeIntervalTarget,
      deliveryClasses,
      senderSiDos,
      receiverSiDos,
      clientId,
    ]
  )
}

function statsKeyWithParams(
  key: string,
  params: StatsParams,
  extras?: { [s in string]: string }
) {
  const queryParams = new URLSearchParams()
  if (params.startTime) {
    queryParams.set("start_time", params.startTime)
  }
  if (params.endTime) {
    queryParams.append("end_time", params.endTime)
  }
  if (params.timeIntervalTarget) {
    queryParams.append("time_interval_target", params.timeIntervalTarget)
  }
  const filters: string[] = []
  if (params.deliveryClasses && params.deliveryClasses.length > 0) {
    filters.push(`delivery_class=${params.deliveryClasses.join(",")}`)
  }
  if (params.senderSiDos && params.senderSiDos.length > 0) {
    filters.push(`sender_si_do=${params.senderSiDos.join(",")}`)
  }
  if (params.receiverSiDos && params.receiverSiDos.length > 0) {
    filters.push(`receiver_si_do=${params.receiverSiDos.join(",")}`)
  }
  if (params.clientId) {
    filters.push(`client_id=${params.clientId}`)
  }
  if (filters.length > 0) {
    queryParams.append("filter", filters.join(";"))
  }
  if (extras) {
    Object.entries(extras).forEach(([k, v]) => {
      queryParams.append(k, v)
    })
  }
  return `${key}?${queryParams.toString()}`
}

function recent9am(t: Dayjs) {
  return t.hour() < 9
    ? t.startOf("day").subtract(1, "day").add(9, "hours")
    : t.startOf("day").add(9, "hours")
}

function timeIntervalTarget(params: StatsParams): TimeInterval {
  const now = dayjs()
  let startTime = dayjs(params.startTime)
  let endTime = dayjs(params.endTime)
  if (!params.startTime) {
    if (params.endTime) {
      startTime = recent9am(endTime)
    } else {
      startTime = recent9am(now)
      endTime = startTime.add(1, "day")
    }
  } else if (!params.endTime) {
    endTime = recent9am(startTime).add(1, "day")
  }
  const isRealTime = startTime.isBefore(now) && now.isBefore(endTime)
  switch (params.timeIntervalTarget) {
    default:
    case "target_deliver_time":
      return isRealTime
        ? "target-deliver-time-realtime"
        : "target-deliver-time-past"
    case "event_time":
      return isRealTime ? "event-time-realtime" : "event-time-past"
  }
}

export function useStats(
  params: StatsParams,
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const { data, error } = useSWR<IStats>(
    statsKeyWithParams("/api/stats", params),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  const stats = useMemo(
    () => (data ? new Stats(data, "all", timeIntervalTarget(p)) : undefined),
    [data, p]
  )
  return { stats, isLoading: typeof data === "undefined" && !error }
}

export function useDriversStats(
  params: StatsParams,
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const key = statsKeyWithParams("/api/stats/drivers", params)
  const { data, error, isValidating } = useSWR<
    (IResourceStats<"driverId"> & {
      latestDeliverTime?: string
      latestPickUpTime?: string
    })[]
  >(key, {
    ...(autoRefreshIntervalSeconds === undefined
      ? {}
      : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
  })

  const prevKey = useRef<string>()
  const [isLoading, setLoading] = useState(true)
  const hasData = typeof data !== "undefined" || error
  useEffect(() => {
    if (prevKey.current === key) {
      return
    }
    if (!isValidating && hasData) {
      prevKey.current = key
    }
    setLoading(isValidating)
  }, [key, isValidating, hasData])

  const stats = useMemo(() => {
    const ti = timeIntervalTarget(p)
    return data?.map((s) => new DriverStats(s, "drivers", ti))
  }, [data, p])
  return { stats, isLoading }
}

export function useRegionSetsStats(
  policyType: string,
  params: StatsParams,
  targetEndUser?: "both" | "sender" | "receiver",
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const { data, error } = useSWR<IResourceStats<"regionSetName">[]>(
    statsKeyWithParams(
      `/api/stats/policies/${policyType}/region-sets`,
      params,
      targetEndUser ? { target_end_user: targetEndUser } : {}
    ),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  const stats = useMemo(() => {
    const ti = timeIntervalTarget(p)
    return data?.map(
      (s) => new ResourceStats("regionSetName", s, "region-sets", ti)
    )
  }, [data, p])
  return { stats, isLoading: typeof data === "undefined" && !error }
}

export function useOrganizationsStats(
  params: StatsParams,
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const { data, error } = useSWR<IResourceStats<"organizationId">[]>(
    statsKeyWithParams(`/api/stats/organizations`, params),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  const stats = useMemo(() => {
    const ti = timeIntervalTarget(p)
    return data?.map(
      (s) => new ResourceStats("organizationId", s, "organizations", ti)
    )
  }, [data, p])
  return { stats, isLoading: typeof data === "undefined" && !error }
}

export function useClientsStats(
  params: StatsParams,
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const { data, error } = useSWR<IResourceStats<"clientId">[]>(
    statsKeyWithParams(`/api/stats/clients`, params),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  const stats = useMemo(() => {
    const ti = timeIntervalTarget(p)
    return data?.map((s) => new ResourceStats("clientId", s, "clients", ti))
  }, [data, p])
  return { stats, isLoading: typeof data === "undefined" && !error }
}

export function useAddressesStats(
  params: StatsParams,
  targetEndUser?: "both" | "sender" | "receiver",
  autoRefreshIntervalSeconds?: number
) {
  const p = useStatsParams(params)
  const { data, error } = useSWR<IResourceStats<"siDo" | "siGunGu">[]>(
    statsKeyWithParams(
      `/api/stats/addresses`,
      params,
      targetEndUser ? { target_end_user: targetEndUser } : {}
    ),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  const stats = useMemo(() => {
    const ti = timeIntervalTarget(p)
    return data?.map(
      (s) => new ResourceStats(["siDo", "siGunGu"], s, "addresses", ti)
    )
  }, [data, p])
  return { stats, isLoading: typeof data === "undefined" && !error }
}

type EventCountsRequest = StatsParams & {
  windowSizeSecs?: number
  windowTarget?: TargetTimeType
  clientId?: string
} & (
    | {
        resourceType?: "all"
      }
    | {
        resourceType: "drivers"
        driverId: string
      }
    | {
        resourceType: "region-sets"
        policyType: string
        regionSetName: string
      }
    | {
        resourceType: "organizations"
        organizationId: string
      }
    | {
        resourceType: "clients"
        clientId: string
      }
    | {
        resourceType: "addresses"
        siDo: string
        siGunGu: string
      }
  )

export function useEventCounts(
  req?: EventCountsRequest,
  autoRefreshIntervalSeconds?: number
) {
  let key: string | null
  if (!req) {
    key = null
  } else {
    switch (req.resourceType) {
      case undefined:
      case "all":
        key = "/api/stats/event-counts"
        break
      case "drivers":
        key = req.driverId
          ? `/api/stats/drivers/${req.driverId}/event-counts`
          : null
        break
      case "region-sets":
        key =
          req.policyType && req.regionSetName
            ? `/api/stats/policies/${req.policyType}/region-sets/${req.regionSetName}/event-counts`
            : null
        break
      case "organizations":
        key = req.organizationId
          ? `/api/stats/organizations/${req.organizationId}/event-counts`
          : null
        break
      case "clients":
        key = req.clientId
          ? `/api/stats/clients/${req.clientId}/event-counts`
          : null
        break
      case "addresses":
        key =
          req.siDo && req.siGunGu
            ? `/api/stats/addresses/${req.siDo}/${req.siGunGu}/event-counts`
            : null
        break
    }
    const queryParams = new URLSearchParams()
    if (req.startTime) {
      queryParams.set("start_time", req.startTime)
    }
    if (req.endTime) {
      queryParams.append("end_time", req.endTime)
    }
    if (req.timeIntervalTarget) {
      queryParams.append("time_interval_target", req.timeIntervalTarget)
    }
    const filters: string[] = []
    if (req.deliveryClasses && req.deliveryClasses.length > 0) {
      filters.push(`delivery_class=${req.deliveryClasses.join(",")}`)
    }
    if (req.senderSiDos && req.senderSiDos.length > 0) {
      filters.push(`sender_si_do=${req.senderSiDos.join(",")}`)
    }
    if (req.receiverSiDos && req.receiverSiDos.length > 0) {
      filters.push(`receiver_si_do=${req.receiverSiDos.join(",")}`)
    }
    if (req.clientId) {
      filters.push(`client_id=${req.clientId}`)
    }
    if (filters.length > 0) {
      queryParams.append("filter", filters.join(";"))
    }
    if (req.windowSizeSecs) {
      queryParams.append("window_size_secs", req.windowSizeSecs.toString())
    }
    if (req.windowTarget) {
      queryParams.append("window_target", req.windowTarget)
    }
    key = key ? `${key}?${queryParams.toString()}` : null
  }
  const { data, error } = useSWR<IEventWindow[]>(key, {
    ...(autoRefreshIntervalSeconds === undefined
      ? {}
      : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
  })
  const eventWindows = useMemo(() => {
    const ti = timeIntervalTarget(req ?? {})
    return data?.map((e) => new EventWindow(e, req?.resourceType, ti))
  }, [data, req])
  return {
    eventTimeWindows: eventWindows,
    isLoading: typeof data === "undefined" && !error,
  }
}

export function useClientsSummary(
  params: Omit<StatsParams, "timeIntervalTarget">,
  autoRefreshIntervalSeconds?: number
) {
  const { data, error } = useSWR<ClientsSummary>(
    statsKeyWithParams(`/api/stats/clients/summary`, params),
    {
      ...(autoRefreshIntervalSeconds === undefined
        ? {}
        : { refreshInterval: 1000 * autoRefreshIntervalSeconds }),
    }
  )
  return {
    clientsSummary: data,
    isLoading: typeof data === "undefined" && !error,
  }
}
